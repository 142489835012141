import React from 'react';
import "./Gallery.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import DubaiJobs from "../Images/DubaiJobs.jpeg";
import DubaiJobs3 from "../Images/DubaiJobs3.jpeg";
import DubaiJobs2 from "../Images/DubaiJobs2.jpeg";
import DubaiVisa from "../Images/DubaiVisa.jpeg";
import FreelanceVisa from "../Images/FreelanceVisa.jpeg";
import GulfCountries from "../Images/GulfCountries.jpeg";
import Visa from "../Images/Visa.jpeg";
import Visitings from "../Images/Visitings.jpeg";
import UK from "../Images/UKJobs.jpg";
import Europe from "../Images/EuropeJobs.jpg";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        lineHeight: "30px",
        textAlign: "center",
        color: "white",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      &gt;
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "rgba(0, 0, 0, 0.5)",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        lineHeight: "30px",
        textAlign: "center",
        color: "white",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      &lt;
    </div>
  );
}

export default function Gallery() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-gallery">
      <h2 className="mb-5 w-100 d-flex justify-content-center">Our Media</h2>
      <Slider {...settings}>
        <div className="box">
          <img src={DubaiJobs} alt="Dubai Jobs" />
        </div>
        <div className="box">
          <img src={DubaiJobs3} alt="Dubai Jobs 3" />
        </div>
        <div className="box">
          <img src={DubaiJobs2} alt="Dubai Jobs 2" />
        </div>
        <div className="box">
          <img src={DubaiVisa} alt="Dubai Visa" />
        </div>
        <div className="box">
          <img src={UK} alt="UK Jobs" />
        </div>
        <div className="box">
          <img src={Europe} alt="Europe Jobs" />
        </div>
        <div className="box">
          <img src={FreelanceVisa} alt="Freelance Visa" />
        </div>
        <div className="box">
          <img src={Visa} alt="Visa" />
        </div>
        <div className="box">
          <img src={Visitings} alt="Visitings" />
        </div>
        <div className="box">
          <img src={GulfCountries} alt="Gulf Countries" />
        </div>
      </Slider>
    </div>
  );
}
