import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className="footer">
      <div className="row footer-contacts m-5">
        <div className="col-md-4 ">
          <h4 className="headings">Quick Links</h4>
          <Link to="/" className="text-white text-decoration-none">Home</Link> <br />
          <Link to="/about" className="text-white text-decoration-none">About</Link> <br />
          <Link to="/services" className="text-white text-decoration-none">Services</Link> <br />
          <Link to="/careers" className="text-white text-decoration-none">Careers</Link> <br />
          <Link to="/contact" className="text-white text-decoration-none">Contact Us</Link> <br />
        </div>

        <div className="col-md-4">
          <h4 className="headings">Contact Us</h4>
          <p>
            <FontAwesomeIcon icon={faEnvelope}  className="icons"/>
            <a href="mailto:admin@georkingsworldtours.co.ke" className="ms-3 text-white text-decoration-none">
              admin@georkingsworldtours.co.ke
            </a>
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone}   className="icons"/>
            <a href="tel:+254715529912" className="ms-3 text-white text-decoration-none">
              +254715529912
            </a>
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone}   className="icons" />
            <a href="tel:+971588399215" className="ms-3 text-white text-decoration-none">
              +971588399215
            </a>
          </p>
          <div className="socialMedia ps-3">
            <a href="https://vm.tiktok.com/ZMrHejCcC/">
              <FontAwesomeIcon icon={faTiktok} bounce className="icon-white me-4 icons bg-dark p-1" />
            </a>
            <a href="https://wa.me/971588399215">
              <FontAwesomeIcon icon={faWhatsapp} bounce className="me-4 icons bg-success p-1" />
            </a>
            <a href="https://wa.me/254715529912">
              <FontAwesomeIcon icon={faWhatsapp}  bounce className="me-4 icons bg-success p-1" />
            </a>
          </div>
        </div>

        <div className="col-md-4"></div>
      </div>
      <div className="copyright">
        <p className="text-white text-decoration-none">
          Copyright &copy; 2024 GEORKINGS WORLD TOURS AND TRAVELS
        </p>
      </div>
    </div>
  );
}
