import React from "react";
import "./AboutUs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

export default function AboutUs() {
  return (
    <div className="about-us">
      <div className="row">
        <div className="col-md-6 d-flex flex-column justify-content-center shadow p-3">
          <div className="heading mt-5">
            <h2>ABOUT US</h2>
          </div>
          <div className="mt-2 ">
            <p>
              At GEORKINGS WORLD TOURS AND TRAVELERS LIMITED, we are passionate
              about making travel accessible and effortless for everyone. With
              years of experience in the industry, we've become the go-to agency
              for travelers seeking adventure, relaxation, and everything in
              between. Our team of dedicated professionals works tirelessly to
              ensure that your travel experience is seamless from start to finish.
            </p>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img
            src="https://images.pexels.com/photos/158398/niagara-falls-waterfall-horseshoe-158398.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            alt="Georkings World Tours"
            className="img-fluid shadow"
            style={{ maxHeight: "60vh", maxWidth: "100%" }}
          />
        </div>
      </div>
    </div>
  );
}
