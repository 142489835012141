// Navigation.js
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import logo from "../logo.png";
import "./Navigation.css";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-white">
      <Container>
        <Navbar.Brand href="#">
          <img src={logo} className="logo ms-5" alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" className="page text-decoration-none">
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/about"
              className="page text-decoration-none"
            >
              About
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/services"
              className="page text-decoration-none"
            >
              Services
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/careers"
              className="page text-decoration-none"
            >
              Careers
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/contact"
              className="page text-decoration-none"
            >
              Contacts
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
