import React from "react";
import "./Home.css";
import Navigation from "../components/Navigation.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../components/Footer.js";
import Carousel from "react-bootstrap/Carousel";
import AboutUs from "../components/AboutUs.js";
import Gallery from "../components/Gallery.js";
import ServicesPage from "../components/ServicePage.js";

export default function Home() {
  return (
    <div className="homePage">
      <div className="home-nav">
        <Navigation />
      </div>

      
          <Carousel>
            <Carousel.Item>
              <h1>DISCOVER THE WORLD</h1>
              <img
                src="https://images.pexels.com/photos/1089645/pexels-photo-1089645.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                className="w-100"
              />
            </Carousel.Item>
            <Carousel.Item>
              <h1>DISCOVER THE WORLD</h1>
              <img
                src="https://images.pexels.com/photos/24181855/pexels-photo-24181855/free-photo-of-elephant-in-a-safari.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                className="w-100"
              />
            </Carousel.Item>
            <Carousel.Item>
              <h1>DISCOVER THE WORLD</h1>
              <img
                src="https://images.pexels.com/photos/4577545/pexels-photo-4577545.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                className="w-100"
              />
            </Carousel.Item>
          </Carousel>
      

      <AboutUs />

      <ServicesPage/>
      <Gallery/>
      

      <div className="home-footer">
        <Footer />
      </div>
    </div>
  );
}
