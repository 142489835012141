import React from "react";
import "./Services.css";
import FreelanceVisa from "../Images/FreelanceVisa.jpeg";
import Visitings from "../Images/Visitings.jpeg";
import Navigation from "../components/Navigation.js";
import Footer from "../components/Footer.js";
import Visa from "../Images/Visa.jpeg";
import DubaiVisa from "../Images/DubaiVisa.jpeg";
import DubaiJobs4 from "../Images/DubaiJobs4.jpeg";
import DubaiJobs from "../Images/DubaiJobs.jpeg";
import { Link } from "react-router-dom";


export default function Services() {
  return (
    <div className="services">
      <Navigation />
      <div className="container services-container">
        <h1>Our Services</h1>
        <p>
          Our travel consultancy specializes in crafting personalized travel
          experiences
          <br /> tailored to your unique preferences and budget.
          <br />
        </p>
      </div>
      <div className="container service">
        <div className="row shadow mt-5 ms-5 service-holder">
          <div className="col-md-6 mt-5 mb-5">
            <img src={Visitings} alt="" className="serviceImage" />
          </div>
          <div className="col-md-6 mt-5">
            <h3>Travel and Tourism</h3>
            <p>
              Embark on a journey of discovery with GEORKINGS WORLD TOURS AND
              TRAVELERS LIMITED experiences. Whether you're seeking the thrill
              of adventure in remote destinations or the tranquility of secluded
              beaches, our travel experts craft itineraries that cater to your
              every desire. From cultural tours to wildlife safaris, we ensure
              that each trip is memorable and enriching.
            </p>
            <Link to="/contact" className="btn btn-primary text-white text-decoration-none">Contact Us</Link> 
          </div>
        </div>
        <div className="row shadow mt-5 ms-5 service-holder">
          <div className="col-md-6 mt-5 mb-5">
            <img src={DubaiJobs4} alt="" className="serviceImage" />
          </div>
          <div className="col-md-6 mt-5">
            <h4>Abroad Job Seekers</h4>
            <p>
              GEORKINGS WORLD TOURS AND TRAVELERS LIMITED is your bridge to
              international career opportunities. We specialize in connecting
              skilled professionals with reputable employers across the globe.
              Our services include resume building, interview preparation, and
              legal assistance to ensure a smooth transition to your new job
              abroad.
            </p>
            <Link to="/contact" className="btn btn-primary text-white text-decoration-none">Contact Us</Link> 
          </div>
        </div>
        <div className="row shadow mt-5 ms-5 service-holder">
          <div className="col-md-6 mt-5 mb-5">
            <img
              src="https://images.unsplash.com/photo-1551882547-ff40c63fe5fa?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
              className="serviceImage"
            />
          </div>
          <div className="col-md-6 mt-5">
            <h4>Hotel Booking</h4>
            <p>
              With our extensive network of hotel partners, we guarantee the
              perfect accommodation for every budget and preference. From luxury
              resorts to cozy bed and breakfasts, we secure the best rates and
              exclusive amenities to enhance your stay
            </p>
            <Link to="/contact" className="btn btn-primary text-white text-decoration-none">Contact Us</Link>
          </div>
        </div>
        <div className="row shadow mt-5 ms-5 service-holder">
          <div className="col-md-6 mt-5 mb-5">
            <img src={DubaiVisa} alt="" className="serviceImage" />
          </div>
          <div className="col-md-6 mt-5">
            <h4> Visa Application</h4>
            <p>
              Navigating the complexities of visa applications can be daunting.
              Our experienced visa consultants provide personalized assistance
              throughout the entire process, from document preparation to
              submission, ensuring a high success rate for your travel or work
              visas.
            </p>
            <Link to="/contact" className="btn btn-primary text-white text-decoration-none">Contact Us</Link>
          </div>
        </div>
        <div className="row shadow mt-5 ms-5 service-holder">
          <div className="col-md-6 mt-5 mb-5">
            <img
              src="https://images.unsplash.com/photo-1436491865332-7a61a109cc05?q=80&w=1474&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
              className="serviceImage"
            />
          </div>
          <div className="col-md-6 mt-5">
            <h4>Flight Booking</h4>
            <p>
              GEORKINGS WORLD TOURS AND TRAVELERS LIMITED offers competitiv e
              pricing on airfare to destinations worldwide. Ou real-timer
              booking system allows you to find and book the most convenient
              flights, taking advantage of special deals and discounts to save
              you mone
            </p>
            <Link to="/contact" className="btn btn-primary text-white text-decoration-none">Contact Us</Link> <br />
          </div>
        </div>
        <div className="row shadow mt-5 ms-5 service-holder">
          <div className="col-md-6 mt-5 mb-5">
            <img src={Visa} alt="" className="serviceImage" />
          </div>
          <div className="col-md-6 mt-5">
            <h4>Visa Change A2A</h4>
            <p>
              For travelers needing to change their visa status, our
              airport-to-airport (A2A) service is the solution. We manage the
              entire process, ensuring a hassle-free experience as you
              transition from one visa to another without leaving the airport
            </p>
            <Link to="/contact" className="btn btn-primary text-white text-decoration-none">Contact Us</Link> <br />
          </div>
        </div>
        <div className="row shadow mt-5 ms-5 service-holder">
          <div className="col-md-6 mt-5 mb-5">
            <img src={FreelanceVisa} alt="" className="serviceImage" />
          </div>
          <div className="col-md-6 mt-5">
            <h4>Freelance Visa Application</h4>
            <p>
              The freelance economy is booming, and we're here to help you be a
              part of it. Our team assists with obtaining freelance visas,
              providing guidance on the requirements and procedures for various
              countries, so you can work independently on a global scale.
            </p>
            <Link to="/contact" className="btn btn-primary text-white text-decoration-none">Contact Us</Link> <br />
          </div>
        </div>
        <div className="row shadow mt-5 ms-5 service-holder mb-5">
          <div className="col-md-6 mt-5 mb-5">
            <img src={DubaiJobs} alt="" className="serviceImage" />
          </div>
          <div className="col-md-6 mt-5">
            <h4>All Kinds of jobs in Gulf</h4>
            <p>
              GEORKINGS WORLD TOURS AND TRAVELERS LIMITED connects job seekers
              with a multitude of employment opportunities in the Gulf region.
              From construction to hospitality, we have access to a diverse
              range of industries, helping you find the right job that matches
              your skills and aspirations.
            </p>
            <Link to="/contact" className="btn btn-primary text-white text-decoration-none">Contact Us</Link> <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
