import React from "react";
import Navigation from "../components/Navigation.js";
import "./Contact.css";
import ContactPages from "../components/ContactPages.js";
import Footer from "../components/Footer.js";

export default function Contact(){
    return (
      <div className="contact">
        <div className="contact-nav">
          <Navigation />
        </div>

        <div className="contact-page">
          <div className="contact-page-contents">
            <div className="contact-head">
              <h1>Contact Our team</h1>
              <p>
                Have any questions about our services? We are here to help!
                <br />
                Chat to our friendly team 24/7 and get on board!
              </p>
            </div>
            <div className="client-details">
              <ContactPages />
            </div>
          </div>
        </div>

        <div className="contact-footer">
          <Footer />
        </div>
      </div>
    );
}