import React from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

export default function CareerPage() {
    // Define an array of PDF file paths and titles
    const jobDetails = [
        { id: 1, title: 'Canada Jobs', pdf: '/pdfs/Canada-Demand-Letter.pdf' },
        { id: 2, title: 'Denmark Jobs', pdf: '/pdfs/Denmark-Demand-Letter.pdf' },
        { id: 3, title: 'Italy Jobs', pdf: '/pdfs/Italy-Demand-Letter.pdf' },
        { id: 4, title: 'Luxembarg Jobs', pdf: '/pdfs/Luxembourg-Demand-Letter.pdf' },
        { id: 5, title: 'UK Jobs', pdf: '/pdfs/UK-Demand-Letter.pdf' },
        { id: 6, title: 'Dubai Jobs', pdf: '/pdfs/Dubai-Jobs.pdf' }
    ];

    return (
        <div className="career-page w-100 h-30">
            <div className="row d-flex align-items-center">
                <div className="col-md-6">
                    <h1 className="m-5">Available Jobs</h1>
                </div>
                <div className="col-md-6 d-flex justify-content-end">
                    <Button variant="primary">
                        <a href="https://wa.me/254715529912" className="text-white  text-decoration-none">
                            Apply Here
                        </a>
                    </Button>
                </div>
            </div>

            <Row xs={1} md={3} className="g-4 m-5">
                {jobDetails.map((job) => (
                    <Col key={job.id}>
                        <Card border="danger" style={{ width: '24rem', height: '14rem' }} className="mb-5">
                            <Card.Body>
                                <Card.Title>{job.title}</Card.Title>
                                <Card.Text>
                                    Find out more about the {job.title} role by downloading the job description.
                                </Card.Text>
                            </Card.Body>
                            <Button variant="primary" style={{ width: '10rem' }} className="mb-3 ms-3">
                                <a href={job.pdf} download className="text-white text-decoration-none">
                                    Description
                                </a>
                            </Button>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}
