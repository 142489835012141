import React from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


const servicesData = [
    {
        id: 1,
        title: 'Travels and Tourism',
        text: 'Embark on a journey of discovery with our curated travel experiences.',
        imgSrc: 'https://images.pexels.com/photos/731217/pexels-photo-731217.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 2,
        title: 'Abroad Job Seekers',
        text: 'We are your bridge to international career opportunities.',
        imgSrc: 'https://plus.unsplash.com/premium_photo-1663089819902-b4a7321f38e0?q=80&w=1510&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 3,
        title: 'Hotel Booking',
        text: 'We guarantee the perfect accommodation for every budget and preference.',
        imgSrc: 'https://images.pexels.com/photos/271639/pexels-photo-271639.jpeg'
    },
    {
        id: 4,
        title: 'Visa Application',
        text: 'Our experienced visa consultants provide personalized assistance.',
        imgSrc: 'https://images.pexels.com/photos/7235894/pexels-photo-7235894.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 5,
        title: 'Flight Booking',
        text: 'We offer competitive pricing on airfare to destinations worldwide.',
        imgSrc: 'https://images.pexels.com/photos/16232303/pexels-photo-16232303/free-photo-of-person-reading-book-in-airplane.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
    },
    {
        id: 6,
        title: 'Visa Change A2A',
        text: 'For travelers needing to change their visa status, our airport-to-airport (A2A) service provides a hassle-free solution.',
        imgSrc: 'https://plus.unsplash.com/premium_photo-1684407617010-4ba587dc20f8?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 7,
        title: 'Freelance Visa Application',
        text: 'Our team assists in obtaining freelance visas, providing guidance on requirements and procedures for various countries.',
        imgSrc: 'https://plus.unsplash.com/premium_photo-1661414779271-d8478e2944bc?q=80&w=1471&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
        id: 8,
        title: 'All kinds of jobs in Gulf',
        text: 'We connect job seekers with a multitude of employment opportunities in the Gulf region.',
        imgSrc: 'https://images.unsplash.com/photo-1504670073073-6123e39e0754?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    }
];

export default function ServicesPage() {
    // Calculate the number of items in the last row and add empty items if needed
    const filledData = [...servicesData];
    const itemsInLastRow = servicesData.length % 3;
    const emptyItems = itemsInLastRow ? 3 - itemsInLastRow : 0;
    for (let i = 0; i < emptyItems; i++) {
        filledData.push({ id: `empty-${i}`, title: '', text: '', imgSrc: '' });
    }

    return (
        <div className="services w-100">
            <h4 className="heading text-center mb-4">Our Services</h4>
            <Row xs={1} md={3} className="g-4 justify-content-center">
                {filledData.map((service) => (
                    <Col key={service.id} className="d-flex justify-content-center">
                        {service.imgSrc ? (
                            <Card style={{ width: '18rem', height: '25rem' }} className="mb-4">
                                <Card.Img
                                    variant="top"
                                    height="200px"
                                    src={service.imgSrc}
                                    className="card-img-top"
                                />
                                <Card.Body>
                                    <Card.Title className="text-center">{service.title}</Card.Title>
                                    <Card.Text className="text-center">
                                        {service.text}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        ) : (
                            // Placeholder for empty items to maintain layout
                            <Card style={{ width: '18rem', height: '25rem', border: 'none', boxShadow: 'none' }} className="mb-4">
                                <Card.Body className="d-flex justify-content-center align-items-center">
                                    <Card.Text className="text-center">
                                        &nbsp;
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                ))}
            </Row>
        </div>
    );
}
