import React from "react";
import Navigation from "../components/Navigation.js";
import "./About.css";
import Footer from "../components/Footer.js";
import AboutUs from "../components/AboutUs.js";

export default function About() {
  return (
    <div className="aboutPage">
      <Navigation />

      <div className="hero">
        <div className="hero-page">
          <h1 className="text-white">GEORKINGS WORLD TOURS AND TRAVELERS LIMITED</h1>
        </div>
      </div>

      <div className="about-us">
        <AboutUs />
      </div>
    

      <div className=" container m-5">
        <div className="row m-5">
          <div className="col-md-4 mt-5 mb-5 me-5">
            <h4>Our Mission</h4>
            <p>
              To empower individuals and businesses through top-tier travel and
              employment services, fostering cultural exchange and global
              connectivity.
            </p>
          </div>
          <div className="col-md-4 mt-5 mb-5 me-5">
            <h4>Our Vision</h4>
            <p>
              To be the leading travel and employment agency in the industry,
              renowned for our commitment to excellence and our contribution to
              global travel and workforce mobility.
            </p>
          </div>
          <div className="col-md-4 mt-5 mb-5 me-5">
            <h4>Core Values</h4>
            <p>
              Customer satisfaction, Integrity, Innovation and Collaboration
              among clients and partners.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
