import React from "react";
import Navigation from "../components/Navigation.js";
import Footer from "../components/Footer.js";
import CareerPage from "../components/CareerPage.js";
export default function Career(){
    return(
        <div className="Career">
            <Navigation/>
            <CareerPage/>
            <Footer/>
        </div>
    )
}